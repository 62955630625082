import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ScheduleDataService } from 'src/services/schedule-data.service';

import { UIService } from 'src/services/ui.service';
import { LoggingService } from 'src/services/logging.service';
import { XLSService } from 'src/services/xls.service';
import { RegionDataService } from 'src/services/region-data.service';
import dayjs from 'dayjs';
import { ContextService } from 'src/services/context.service';
import { first, tap } from 'rxjs/operators';
import { Subscription, zip } from 'rxjs';
import { Router } from '@angular/router';
import { LocalService } from 'src/services/local.service';
import { FullDepartment } from 'src/commontypes/manning';
import { SentryService } from 'src/services/sentry.service';

@Component({
  selector: 'app-actualsimport',
  styleUrls: ['./actualsimport.component.scss'],
  templateUrl: './actualsimport.component.html',
})
export class ActualsImportComponent implements OnInit, OnDestroy {
  constructor(
    private scheduleData: ScheduleDataService,
    private local: LocalService,
    private sentryService: SentryService,
    private regionData: RegionDataService,
    private log: LoggingService,
    private xls: XLSService,
    private ui: UIService,
    private context: ContextService,
    private router: Router
  ) {}

  @ViewChild('uploadarea') uploadControl;

  isWorking = false;
  allDeps: FullDepartment[] = [];
  loadedDeps = null;
  totalUpdate = 0;
  totalUUpdate = 0;
  totalBad = 0;
  badShifts = [];
  fileLoaded = false;
  hotel$: Subscription;
  holidex: string;
  startsMonday?: boolean;

  ngOnInit() {
    this.hotel$ = this.context.getCurrentBasicHotel$().subscribe((hotel) => {
      if (hotel?.id) {
        this.holidex = hotel.holidexCode;
        this.startsMonday = hotel.firstWeekday === 'MONDAY';
        this.regionData.getFullDepartmentsForHotel().subscribe((d) => {
          this.allDeps = d;
        });
        this.loadUnconfirmedShifts();
      } else {
        this.allDeps = [];
        this.badShifts = [];
        this.holidex = undefined;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.hotel$) {
      this.hotel$.unsubscribe();
      this.hotel$ = undefined;
    }
  }

  startImport() {
    this.uploadControl.nativeElement.click();
  }

  async exportActualsTemplate() {
    this.xls.startSheet();
    this.xls.addRow(['Department', 'Outlet Index', 'T & A ID', 'Date In', 'Time In', 'Date Out', 'Time Out'], 'EEEEEE');
    this.allDeps.forEach((d) => {
      const name = d.outletType && d.name !== 'RESTAURANTS' && d.name !== 'BARS' ? `${d.outletType} ${d.name}` : d.outletType || d.name;
      this.xls.addRow([
        name + (d.subDeptName ? ` ${d.subDeptName}` : ''),
        d.outletType ? d.outletIndex : '',
        this.holidex + '?????',
        dayjs().subtract(1, 'week').format('YYYY/MM/DD'),
        '08:00:00',
        dayjs().subtract(1, 'week').format('YYYY/MM/DD'),
        '17:00:00',
      ]);
    });
    this.xls.addRow(['', '', '', '', '', '', '']);
    await this.xls.outputSheet(`actualstemplate-${this.holidex}.xlsx`);
  }

  async uploader(event) {
    this.ui.info('Processing import file...');
    this.isWorking = true;
    await this.xls.openFile(event.target.files[0]);
    this.uploadControl.nativeElement.value = ''; //allows us to do this again
    //load all the rows into memory
    const rows = [];
    const errorRows: Array<{ i; error }> = [];
    for (let i = 2; i <= this.xls.numRows(); i += 1)
      try {
        if (!this.xls.getCellVal(1, i)) break; //end of file
        let start = null,
          end = null;

        try {
          const startDate = dayjs.utc(this.xls.getCellVal(4, i).toString());
          const endDate = dayjs.utc(this.xls.getCellVal(6, i).toString());

          const startT = this.xls.getCellVal(5, i).toString() ?? '0:00:00';
          const endT = this.xls.getCellVal(7, i).toString() ?? '0:00:00';

          let startTime = dayjs.utc(startT);
          let endTime = dayjs.utc(endT);
          if (isNaN(+startTime)) {
            startTime = dayjs.utc(`${dayjs.utc().format('YYYY/MM/DD')}T${startT}`);
          }
          if (isNaN(+endTime)) {
            endTime = dayjs.utc(`${dayjs.utc().format('YYYY/MM/DD')}T${endT}`);
          }

          start = this.context.contextDateToISO(startDate.add(startTime.hour(), 'hour').add(startTime.minute(), 'minute'));
          end = this.context.contextDateToISO(endDate.add(endTime.hour(), 'hour').add(endTime.minute(), 'minute'));
        } catch (e) {
          //parsing failed on these dates - so we don't have them
          start = null;
          end = null;
        }

        let [department, subDept] = this.xls.getCellVal(1, i)?.toString()?.split(' ');
        let outletType;
        if (department === 'RESTAURANT' || department === 'BAR') {
          outletType = department;
          if (subDept) {
            department = subDept;
            subDept = undefined;
          } else {
            department += 'S';
          }
        }

        rows.push({
          personnelNo: this.xls.getCellVal(3, i),
          department,
          subDept,
          outletIndex: this.xls.getCellVal(2, i).toString().trim().length > 0 ? +this.xls.getCellVal(2, i) : undefined,
          outletType,
          start,
          end,
          noTimesGiven: !this.xls.getCellVal(5, i),
          importRow: i,
        });
      } catch (error) {
        this.log.debug('parse throwing... on row ' + i);
        this.log.debug(error);
        errorRows.push({ i, error });
      }
    if (errorRows.length > 0) {
      this.sentryService.sendMessage('Error importing file', 'warning', {
        file: event.target.files[0]?.name,
        lines: errorRows.map(({ i }) => i),
        error: errorRows[0].error,
        stack: errorRows[0].error?.stack,
      });
      this.ui.acknowledgeError(
        `Unable to parse ${event.target.files[0].name} in rows ${errorRows.map(({ i }) => i).join(', ')}`,
        undefined,
        'Error importing data'
      );
    }
    this.ui.info(rows.length + ' records loaded');
    this.log.debug(rows);
    //reduce to get a list of departments containing shifts with a start and end date
    let astart = null,
      aend = null;
    let deps = rows.reduce((a, v) => {
      let myDep = a.find(
        (dv) => dv.department == v.department && dv.subDept == v.subDept && dv.outletIndex == v.outletIndex && dv.outletType == v.outletType
      );
      if (!myDep) {
        myDep = {
          deptFullName: v.outletType
            ? v.department === 'RESTAURANTS' || v.department === 'BARS'
              ? `${v.outletType} ${v.outletIndex}`
              : `${v.outletType} ${v.outletIndex} ${v.department}`
            : v.department + (v.subDept ? ' ' + v.subDept : ''),
          department: v.department,
          subDept: v.subDept,
          outletType: v.outletType,
          outletIndex: v.outletIndex,
          shifts: [],
          start: null,
          end: null,
          badId: 0,
          badSchedule: 0,
          badTimes: 0,
          matched: 0,
          unplanned: 0,
          badDepartment: 0,
          problemRows: [],
        };
        a.push(myDep);
      }
      myDep.shifts.push(v);
      if (v.start) {
        if (!myDep.start || myDep.start > v.start) myDep.start = v.start;
        if (!myDep.end || myDep.end < v.end) myDep.end = v.end;
        if (!astart || astart > v.start) astart = v.start;
        if (!aend || aend < v.end) aend = v.end;
      }
      return a;
    }, []);

    this.log.debug(deps);

    let schedules = [];
    if (astart < aend) {
      //if there are some decent dates on this file then take them
      //load schedules for all departments
      schedules = await this.scheduleData.getScheduleStatus(astart, aend).pipe(first()).toPromise();
    }
    //for each department on the load data and compare shifts, create shift capture according to the rules

    for await (const dep of deps) {
      let depSched = schedules.filter(
        (s) =>
          s.departmentName == dep.department &&
          (s.subDeptName ? s.subDeptName == dep.subDept : !dep.subDept) &&
          (s.outletType ? s.outletIndex == dep.outletIndex && s.outletType == dep.outletType : !dep.outletType)
      );
      try {
        await this.processDepartment(dep, depSched);
      } catch (err) {
        this.sentryService.showAndSendError(err, 'Unable to process schedule data', undefined, { ...dep }, 'actual-import-process');
        this.isWorking = false;
        return;
      }
    }

    //show the list of departments and status
    this.loadedDeps = deps;
    this.totalUpdate = deps.reduce((a, v) => a + v.matched, 0);
    this.totalUUpdate = deps.reduce((a, v) => a + v.unplanned, 0);
    this.totalBad = deps.reduce((a, v) => a + v.badId + v.badDepartment + v.badSchedule + v.badTimes, 0);
    this.isWorking = false;
  }

  async doImport() {
    this.isWorking = true;
    try {
      for await (const dep of this.loadedDeps) await this.doDepartmentImport(dep);
      this.fileLoaded = true;
      this.loadUnconfirmedShifts();
    } catch (err) {
      this.sentryService.showAndSendError(err, 'Unable to capture shifts', undefined, undefined, 'actual-import-import');
    }
    this.isWorking = false;
  }

  loadUnconfirmedShifts() {
    this.log.debug('Checking', this.startsMonday);
    this.scheduleData
      .getRecentPredraftCaptureShifts()
      .pipe(first())
      .subscribe((data) => {
        //process data into rows by department
        this.badShifts = data.reduce((a, v) => {
          let start = dayjs(v.start).startOf('week').format('YYYY-MM-DD');
          if (this.startsMonday)
            //actually start on a monday
            start = dayjs(v.start).subtract(1, 'day').startOf('week').add(1, 'day').format('YYYY-MM-DD');
          let r = a.find(
            (ca) =>
              ca.start == start &&
              ca.departmentName == v.shift.departmentName &&
              (ca.subDeptName ? ca.subDeptName == v.shift.subDeptName : v.shift.subDeptName) &&
              (ca.outletType ? ca.outletType == v.shift.outletType && ca.outletIndex == v.shift.outletIndex : !v.shift.outletIndex)
          );
          if (!r) {
            r = {
              deptFullName: v.shift.outletType
                ? v.shift.departmentName === 'RESTAURANTS' || v.shift.departmentName === 'BARS'
                  ? `${v.shift.outletType} ${v.shift.outletIndex}`
                  : `${v.shift.outletType} ${v.shift.outletIndex} ${v.shift.departmentName}`
                : v.shift.departmentName + (v.shift.subDeptName ? ' ' + v.shift.subDeptName : ''),
              start: start,
              subDeptName: v.shift.subDeptName,
              departmentName: v.shift.departmentName,
              outletIndex: v.shift.outletIndex,
              outletType: v.shift.outletType,
              count: 0,
            };
            a.push(r);
          }
          r.count += 1;
          return a;
        }, []);
      });
  }

  showActuals(bsr) {
    let startMonday = this.local.get('scheduleshowtime', 0) == 1;
    this.router.navigate([
      '/main/captureshifts',
      {
        showTime: startMonday ? 0 : 1,
        startDate: +dayjs(bsr.start),
        department: bsr.departmentName,
        outletIndex: bsr.outletIndex,
        outletType: bsr.outletType,
        subDeptName: bsr.subDeptName,
        backRoute: 'main/actualsimport',
      },
    ]);
  }

  async processDepartment(dep, depSched) {
    if (
      !this.allDeps.find((d) => {
        if (d.name != dep.department) return false;
        if (d.subDeptName != dep.subDept) return false;
        if (d.outletIndex && d.outletIndex != dep.outletIndex) return false;
        return true;
      })
    ) {
      dep.badDepartment = dep.shifts.length;
      dep.problemRows = dep.shifts.map((s) => s.importRow);
      return;
    }

    let depStore = {
      name: dep.department,
      ...(dep.outletType && { outletType: dep.outletType }),
      ...(dep.outletType && { outletIndex: dep.outletIndex }),
      ...(dep.subDept && { subDeptName: dep.subDept }),
    };
    dep.depStore = depStore;

    let persons;
    //load the shifts to match
    persons = (await this.scheduleData.getScheduledAndCapturedShifts(dep.start, dep.end, null, depStore, false).toPromise()).data;

    //for each shift
    dep.shifts.forEach((shift) => {
      //check person is in department - mark as bad record
      let per = persons.find((p) => p.personnelNo == shift.personnelNo);
      if (!shift.start || !shift.end || dayjs(shift.end).isBefore(shift.start)) {
        shift.updateStatus = 'BAD TIMES';
        dep.problemRows.push(shift.importRow);
        dep.badTimes += 1;
        return;
      } else shift.per = per;

      if (!per) {
        //bad person - no go
        shift.updateStatus = 'BAD ID';
        dep.badId += 1;
        dep.problemRows.push(shift.importRow);
        return;
      }
      this.log.debug(shift);
      //check schedule for day is unsubmitted - mark as bad record if not
      let sch = depSched.find((s) => s.day == shift.start.slice(0, 10));
      if (!sch || sch.captureStatus == 'SUBMITTED') {
        shift.updateStatus = 'BAD_SCHEDULE';
        dep.problemRows.push(shift.importRow);
        dep.badSchedule += 1;
        return;
      }
      //find a possible shift
      const offLimit = 60;
      let tshift = per.shifts.find((s) => {
        //check is this department shift
        if (s.otherDep) return false;

        let diff = dayjs(shift.start).diff(s.start, 'minutes');

        if (s.noTimesGiven) {
          //if no times are specified we can match non-work shifts
          if (!['OFF', 'LEAVE'].includes(s.type)) return false; //no match
          if (diff < 0 || diff > 24 * 60) return false; //must start within 24 hours of the start of the day
          return true;
        }
        //Must be a work shift and must match within x minutes
        if (s.type != 'WORK') return false;
        if (Math.abs(diff) > offLimit) return false; //check is it inside 2 hours
        return true;
      });
      if (tshift) {
        shift.updateShift = tshift;
        shift.updateStatus = 'OKAY';
        dep.matched += 1;
      } else {
        shift.updateStatus = 'UNPLANNED';
        dep.unplanned += 1;
      }
    });
  }

  async doDepartmentImport(dep) {
    this.log.debug('Starting department', dep);
    //prepare to schedule unplanned shifts
    const unplanned = dep.shifts
      .filter((s) => s.updateStatus == 'UNPLANNED')
      .map((s) => {
        let ns = {
          departmentName: dep.depStore.name,
          ...(dep.depStore.outletType && { outletType: dep.depStore.outletType }),
          ...(dep.depStore.outletType && { outletIndex: dep.depStore.outletIndex }),
          ...(dep.depStore.subDeptName && { subDeptName: dep.depStore.subDeptName }),
          start: s.start,
          end: s.end,
          breaks: 0,
          quantity: 1,
          type: 'WORK',
          absenceType: 'OTHER',
        };
        return this.scheduleData
          .updateShift(s.per.id, 0, ns, ns, true)
          .pipe(first())
          .pipe(
            tap((ns) => {
              s.updateShift = ns;
            })
          );
      });
    //execute scheduling for unplanned shifts
    this.log.debug('Working unplanned ');
    if (unplanned?.length) {
      await zip(...unplanned)
        .pipe(first())
        .toPromise();
    }

    //prepare standard (matched) and unplanned shifts capture save
    const planned = dep.shifts
      .filter((s) => s.updateStatus == 'OKAY' || s.updateStatus == 'UNPLANNED')
      .map((s) => {
        let ms = s.updateShift;
        let capturedShift = {
          id: ms.capturedShifts?.length ? ms.capturedShifts[0].id : 0, //overwrite if we have one
          start: s.start,
          end: s.end,
          breaks: ms.breaks,
          quantity: ms.quantity,
          absence: false,
          absenceComment: null as string,
          absenceType: null,
          status: s.updateStatus == 'OKAY' ? 'DRAFT' : 'PRE_DRAFT',
          importComment: `Unscheduled shift created from file import on ${dayjs().format('YYYY-MM-DD')} line ${s.importRow}`,
        };
        return this.scheduleData.updateCapturedShift(ms.id, capturedShift);
      });
    //execute standard shifts
    this.log.debug('Working planned ');
    if (planned.length > 0) {
      await zip(...planned)
        .pipe(first())
        .toPromise();
    }
    this.log.debug('Done');
  }
}
