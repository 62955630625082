import { Component, OnDestroy, OnInit } from '@angular/core';
import { ScheduleDataService } from 'src/services/schedule-data.service';

import { UIService } from 'src/services/ui.service';
import { LoggingService } from 'src/services/logging.service';
import { RegionDataService } from 'src/services/region-data.service';
import dayjs from 'dayjs';
import { Router } from '@angular/router';
import { ContextService } from 'src/services/context.service';
import { FullDepartment } from 'src/commontypes/manning';
import { forkJoin, Subject, Subscription, timer } from 'rxjs';
import { distinctUntilKeyChanged, first, mergeMap, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { runAndRetry } from 'src/commontypes/util';
import { SentryService } from 'src/services/sentry.service';

@Component({
  selector: 'app-statusdashboard',
  styleUrls: ['./statusdashboard.component.scss'],
  templateUrl: './statusdashboard.component.html',
})
export class StatusDashboardComponent implements OnInit, OnDestroy {
  isRefreshing = false;
  isLoading = true;
  weekStart = new Date();
  commentDate = new Date();
  showTime = 0;
  showTimes = [
    { code: 0, label: 'Week (Mon - Sun)' },
    { code: 1, label: 'Week (Sun - Sat)' },
  ];
  yearRange = `${dayjs().subtract(1, 'years').year()}:${dayjs().add(3, 'years').year()}`;
  updated = undefined;
  usedCache = false;

  public departments: FullDepartment[] = [];
  public rows = [];
  public total: any = {};
  private $refreshData = new Subject();
  private hotel$: Subscription;
  private timer$: Subscription;
  public firstLoad = true;

  constructor(
    private scheduleData: ScheduleDataService,
    private regionData: RegionDataService,
    private uiService: UIService,
    private log: LoggingService,
    private router: Router,
    public context: ContextService,
    private messageService: MessageService,
    private sentryService: SentryService
  ) {}

  ngOnInit() {
    this.firstLoad = true;
    this.hotel$ = this.context
      .getCurrentBasicHotel$()
      .pipe(distinctUntilKeyChanged('id'))
      .subscribe((v) => {
        if (!v.id)
          //no hotel yet
          return;
        this.firstLoad = true;
        this.messageService.clear('bottom-right');
        //match start for hotel
        switch (v.firstWeekday) {
          case 'MONDAY':
            this.showTime = 0;
            break;
          default:
            this.showTime = 1;
            break;
        }

        this.regionData
          .getFullDepartmentsForHotel()
          .pipe(first())
          .subscribe((data) => {
            this.departments = data;
            this.dateChange();
          });
      });
  }

  ngOnDestroy(): void {
    this.messageService.clear('bottom-right');
    if (this.hotel$) {
      this.hotel$.unsubscribe();
      this.hotel$ = undefined;
    }
    if (this.timer$) {
      this.timer$.unsubscribe();
      this.timer$ = undefined;
    }
  }

  refresh() {
    this.isRefreshing = true;
    this.loadData(true);
  }

  refreshData() {
    this.isRefreshing = true;
    this.messageService.clear('bottom-right');
    this.messageService.add({
      id: 'update_schedules',
      key: 'bottom-right',
      severity: 'info',
      summary: 'Checking for new schedules...',
      icon: 'pi-spin pi-spinner',
      sticky: true,
    });
    return this.scheduleData.getBaseScheduleStatusWithStats(this.weekStart, dayjs(this.weekStart).add(6, 'day').toDate()).pipe(
      first(),
      tap((newData: any) => {
        this.messageService.clear('bottom-right');
        this.usedCache = false;
        if (newData) {
          this.updated = new Date();
          this.processData(newData.hotel, newData.data);
        } else {
          this.updated = undefined;
          this.processData({}, []);
        }
        this.isRefreshing = false;
      })
    );
  }

  loadData(forceReload = false) {
    const clearTimer = () => {
      if (this.timer$) {
        this.timer$.unsubscribe();
        this.timer$ = undefined;
      }
    };
    const handleErrorAndRefresh = () => {
      this.messageService.clear('bottom-right');
      this.messageService.add({
        id: 'update_schedules',
        key: 'bottom-right',
        severity: 'warn',
        summary: 'Unable to retrieve schedules, will retry later.',
        icon: 'pi-exclamation-triangle',
        sticky: true,
      });
      this.$refreshData.next();
      this.isRefreshing = false;
    };

    clearTimer();

    this.rows = [];
    // only query for data if we have departments otherwise clear the data
    this.isLoading = true;
    if (this.departments && this.departments.length > 0) {
      this.scheduleData
        .getBaseScheduleStatusWithStats(this.weekStart, dayjs(this.weekStart).add(6, 'day').toDate(), null, true)
        .pipe(first())
        .subscribe((data: any) => {
          if (data) {
            this.updated = new Date();
            this.usedCache = true;
            this.processData(data.hotel, data.data);
          } else {
            this.updated = undefined;
            this.usedCache = false;
            this.rows = [];
            this.total = {};
          }
          this.firstLoad = false;
          this.isLoading = false;

          // every time we are told to refresh we wait some time and then start the refresh process again
          this.$refreshData.subscribe(() => {
            clearTimer();
            this.timer$ = timer(60 * 1000)
              .pipe(
                first(),
                mergeMap(() => this.refreshData())
              )
              .subscribe(
                () => this.$refreshData.next(),
                () => handleErrorAndRefresh()
              );
          });

          clearTimer();

          // kickstart the refresh process with a shorter timer since our first query uses the cache
          this.timer$ = timer(forceReload ? 10 : 1000)
            .pipe(
              first(),
              mergeMap(() => this.refreshData())
            )
            .subscribe(
              () => this.$refreshData.next(),
              () => handleErrorAndRefresh()
            );
        });
    } else {
      this.isLoading = false;
      this.processData({}, []);
    }
  }

  processData(hotelDetail, data) {
    //make a row for every department
    this.rows = this.departments
      .filter((dept) => {
        //filter out departments that are not open these months
        return this.isDepartmentOpen(hotelDetail, dept);
      })
      .map((dept) => {
        //filter by department
        let d = data.filter(
          (l) =>
            dept.name === l.departmentName &&
            (dept.outletType ? dept.outletType === l.outletType && dept.outletIndex === l.outletIndex : !l.outletType) &&
            (dept.subDeptName ? dept.subDeptName === l.subDeptName : !l.subDeptName)
        );
        return {
          order: (d && d[0]?.departmentOrder) || dept.defaultOrder,
          department: dept.label,
          fulldepartment: dept,
          classIndex: ['ROOMS', 'F_AND_B', 'OTHER', 'NON_OPS'].findIndex((v) => dept.class == v),
          submitCount: d.reduce((a, r) => (r.scheduleSubmit ? a + 1 : a), 0),
          approvedCount: d.reduce((a, r) => (r.scheduleStatus == 'APPROVED' ? a + 1 : a), 0),
          autoApprovedCount: d.reduce((a, r) => (r.scheduleStatus == 'AUTO_APPROVED' ? a + 1 : a), 0),
          postChanges: d.reduce((a, r) => a + r.postApprovalShiftUpdates, 0),
          cSubmitCount: d.reduce((a, r) => (r.captureSubmit ? a + 1 : a), 0),
          cApprovedCount: d.reduce((a, r) => (r.captureStatus == 'APPROVED' || r.captureStatus == 'AUTO_APPROVED' ? a + 1 : a), 0),
          scheduledHours: d.reduce((a, r) => a + r.scheduledHours, 0),
          forecastHours: d.reduce((a, r) => a + r.forecastHours, 0),
          // the scheduler/TP uses the forecast reports to filter the status dashboard
          // NB!!: if this changes then the create-report-handler on the backend also need to be changed
          allWOTParts: d.reduce(
            (a, r) => a + (r.forecastReport ? this.scheduleData.calcOptimalHours(r.forecastReport, dept.class, dept.name) : 0),
            0
          ),
        };
      })
      .filter((row) => row.allWOTParts > 0 || row.scheduledHours > 0)
      .sort((a, b) => {
        if (a.classIndex == b.classIndex) return a.order > b.order ? 1 : -1;
        return a.classIndex > b.classIndex ? 1 : -1;
      });
    let nullTot = {
      last: -1,
      scheduleSaved: 0,
      actualSaved: 0,
      actualLocked: 0,
      allDays: 0,
      weeksApproved: 0,
      weeksAutoApproved: 0,
      depCount: 0,
    };
    let last = { ...nullTot };
    this.total = { ...nullTot };
    this.rows.forEach((r) => {
      r.isAutoApproved = !!r.autoApprovedCount;
      r.isApproved = r.approvedCount >= 7;

      if (r.classIndex != last.last) {
        last = { ...nullTot }; //clear totals
        r.newDivision = last;
      } else r.newDivision = null;

      last.last = r.classIndex;
      last.depCount += 1;
      last.allDays += 7;
      last.scheduleSaved += r.submitCount;
      last.actualSaved += r.cSubmitCount;
      last.actualLocked += r.cApprovedCount;
      if (r.isApproved && !r.isAutoApproved) last.weeksApproved += 1;
      if (r.isAutoApproved) last.weeksAutoApproved += 1;

      this.total.depCount += 1;
      this.total.allDays += 7;
      this.total.scheduleSaved += r.submitCount;
      this.total.actualSaved += r.cSubmitCount;
      this.total.actualLocked += r.cApprovedCount;
      if (r.isApproved && !r.isAutoApproved) this.total.weeksApproved += 1;
      if (r.isAutoApproved) this.total.weeksAutoApproved += 1;
    });
  }

  isDepartmentOpen(hotel, dept) {
    this.log.debug('isDepartmentOpen data', hotel, dept);
    if (dept.name == 'RESTAURANTS') {
      //we need to check for it and
      let ri = hotel.restaurants.find((out) => out.index == dept.outletIndex);
      if (!ri) {
        this.sentryService.sendMessage(
          'Unable to find restaurant',
          'warning',
          { hotelRestaurants: hotel?.restaurants, holidexCode: hotel?.holidexCode, dept },
          'status-dash-missing-outlet'
        );
        return false;
      }
      if (ri.fullYear) return true; //always open
      let mns = dayjs(this.weekStart).format('MMMM').toLowerCase();
      let mne = dayjs(this.weekStart).add(6, 'day').format('MMMM').toLowerCase();
      return ri[mns] || ri[mne]; //is open at the start/end of the week
    } else if (dept.name == 'BARS') {
      //we need to check for it and
      let ri = hotel.bars.find((b) => b.index == dept.outletIndex);
      if (!ri) {
        this.sentryService.sendMessage(
          'Unable to find bar',
          'warning',
          { hotelBars: hotel?.bars, holidexCode: hotel?.holidexCode, dept },
          'status-dash-missing-outlet'
        );
        return false;
      }
      if (ri.fullYear) return true; //always open
      let mns = dayjs(this.weekStart).format('MMMM').toLowerCase();
      let mne = dayjs(this.weekStart).add(6, 'day').format('MMMM').toLowerCase();
      return ri[mns] || ri[mne]; //is open at the start/end of the week
    } else return true;
  }

  getDivisionName(index) {
    return ['Rooms Division', 'F and B Division', 'Other Operational', 'Non-Operations Division'][index];
  }

  openDetail(dep, useActual) {
    this.router.navigate([
      useActual ? '/main/captureshifts' : '/main/scheduleshifts',
      {
        showTime: this.showTime,
        startDate: +this.weekStart,
        department: dep.fulldepartment.name,
        outletIndex: dep.fulldepartment.outletIndex,
        outletType: dep.fulldepartment.outletType,
        subDeptName: dep.fulldepartment.subDeptName,
        backRoute: 'main/statusdashboard',
      },
    ]);
  }

  dateChangeTimer = 0;
  dateChangeTimerID = null;

  dateChange() {
    clearTimeout(this.dateChangeTimerID);
    this.dateChangeTimerID = setTimeout(() => this.dateChangeDebounced(), 500);
  }

  dateChangeDebounced() {
    this.commentDate = dayjs(this.weekStart).startOf('week').add(1, 'day').toDate();
    if (this.showTime === 0) {
      this.weekStart = dayjs(this.weekStart).startOf('week').add(1, 'day').toDate();
    } else {
      this.weekStart = dayjs(this.weekStart).startOf('week').toDate();
    }
    this.loadData();
  }

  setToday() {
    this.weekStart = new Date();
    this.dateChange();
  }

  setCal(diff: number) {
    this.weekStart = new Date(+this.weekStart + diff * 24 * 60 * 60 * 1000);
    this.dateChange();
  }

  approveAll(row) {
    let out = 7 - row.submitCount;
    let message = out ? `Schedules for ${out} day(s) have not been saved by the department manager! ` : '';
    this.uiService.confirmAction(message + 'Approve shift schedule for all days?', () => {
      //build an array of requests
      this.uiService.info('Approving shifts');
      runAndRetry(this.scheduleData.setScheduleApprovedForDays(row.fulldepartment, this.weekStart, 7)).subscribe(
        (data) => {
          //saved as requested.
          this.loadData();
          this.uiService.info('Shifts Approved');
        },
        () => {
          this.uiService.error('Errors approving shifts', 'Please retry');
        }
      );
    });
  }

  approveAllDivision(row, index) {
    this.log.debug('approveAllDivision', row.newDivision);
    let aRows = this.rows.slice(index, index + row.newDivision.depCount);
    let out = aRows.reduce((a, r) => a + 7 - row.submitCount, 0);
    let message = out ? `Schedules for some day(s) and department(s) have not been saved by the department managers! ` : '';
    this.uiService.confirmAction(message + 'Approve shift schedule for all days in this division?', () => {
      this.uiService.info('Approving shifts');
      let updates = aRows.map((row) =>
        runAndRetry(this.scheduleData.setScheduleApprovedForDays(row.fulldepartment, this.weekStart, 7), true, 2000)
      );
      forkJoin(updates).subscribe((data) => {
        //saved as requested.
        this.loadData();
        this.uiService.info('Shifts Approved');
      });
    });
  }
}
